import "./index.scss";

import lozad from "lozad";
import Swiper from "swiper/bundle";

import "./particles.js";

// Lazy load init
const observer = lozad();
observer.observe();

// Form fecth
const formElements = document.querySelectorAll("form.fetch");
if (formElements) {
  formElements.forEach((form) => {
    form.addEventListener("submit", (evt) => {
      evt.preventDefault();

      let phone = form.querySelector("input[name='phone']").value;
      let data = new FormData(form);

      // assuming leadgets is a valid function that sends data to the server
      leadgets("lead", data, (r) => {
        if (r.status === 1) {
          Fancybox.close();

          // assuming submit buttons need to be enabled after successful submission
          let submitButtons = form.querySelectorAll(
            "input[type='submit'], button[type='submit']"
          );
          submitButtons.forEach((button) => button.removeAttribute("disabled"));

          let inputFields = form.querySelectorAll("input, textarea");
          inputFields.forEach((input) => {
            if (
              ["submit", "checkbox", "radio", "hidden"].indexOf(input.type) < 0
            ) {
              input.value = "";
              input.parentNode.classList.remove("valid");
            }
          });

          let checkboxes = form.querySelectorAll("input[type='checkbox']");
          checkboxes.forEach((checkbox) => {
            if (checkbox.name !== "agreement") checkbox.checked = false;
          });

          setTimeout(() => {
            fancyboxShow("#thanks", "inline");
          }, 150);

          setTimeout(() => {
            Fancybox.close();
          }, 5000);
        }
      });
    });
  });
}

//smoth scroll
document.addEventListener("DOMContentLoaded", function () {
  const anchors = document.querySelectorAll('a[href*="#"]');

  for (const anchor of anchors) {
    if (anchor.classList.contains("smooth-scroll")) {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const blockID = anchor.getAttribute("href").substr(1);

        document.getElementById(blockID).scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    }
  }
});

// fancybox
Fancybox.bind("[data-fancybox]", {
  // Your custom options
});

const fancyboxShow = (src, type) => {
  Fancybox.show(
    [
      {
        src: src,
        type: type,
      },
    ],
    {
      autoFocus: false,
    }
  );
};

// Input name capitalize / validate
if (document.querySelector("input[name='name']")) {
  function capitalize(input) {
    input.value = input.value.replace(/( |^)[а-яёa-z]/g, function (u) {
      return u.toUpperCase();
    });
  }

  let nameInputs = document.querySelectorAll("input[name='name']");
  nameInputs.forEach((nameInput) => {
    nameInput.addEventListener("input", function () {
      capitalize(this);
    });

    // Regex to check for numbers
    let regnumberform = /[0-9]/g;
    nameInput.oninput = function () {
      if (this.value.match(regnumberform)) {
        this.value = this.value.replace(regnumberform, "");
      } else {
        this.classList.add("is-valid");
      }
    };
  });
}

// E-mail mask / validate
if (document.querySelector("input[type='email']")) {
  const EMAIL_REGEXP =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
  const inputs = document.querySelectorAll("input[type='email']");

  function isEmailValid(value) {
    return EMAIL_REGEXP.test(value);
  }

  function onInput() {
    if (isEmailValid(this.value)) {
      this.classList.remove("is-invalid");
      this.classList.add("is-valid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.remove("disabled--email");
      });
    } else if (this.value.length === 0) {
      this.classList.remove("is-invalid", "is-valid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.remove("disabled--email");
      });
    } else {
      this.classList.add("is-invalid");
      document.querySelectorAll("#submit-btn").forEach(function (button) {
        button.classList.add("disabled--email");
      });
    }
  }

  inputs.forEach(function (input) {
    input.addEventListener("input", onInput);
  });
}

// Phone mask / validate
if (document.querySelector("input[type='tel']")) {
  let phoneInputs = document.querySelectorAll("input[type='tel']");
  let getInputNumbersValue = function (input) {
    return input.value.replace(/\D/g, "");
  };
  let onPhonePaste = function (e) {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input);
    let pasted = e.clipboardData || window.clipboardData;
    if (pasted) {
      let pastedText = pasted.getData("Text");
      if (/\D/g.test(pastedText)) {
        input.value = inputNumbersValue;
        return;
      }
    }
  };
  let onPhoneInput = function (e) {
    let input = e.target,
      inputNumbersValue = getInputNumbersValue(input),
      selectionStart = input.selectionStart,
      formattedInputValue = "";
    if (!inputNumbersValue) {
      return (input.value = "");
    }
    if (input.value.length != selectionStart) {
      if (e.data && /\D/g.test(e.data)) {
        input.value = inputNumbersValue;
      }
      return;
    }
    if (
      ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].indexOf(
        inputNumbersValue[0]
      ) > -1
    ) {
      if (inputNumbersValue[0] == "9")
        inputNumbersValue = "7" + inputNumbersValue;
      let firstSymbols = inputNumbersValue[0] == "8" ? "8" : "+7";
      formattedInputValue = input.value = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
        this.classList.add("is-invalid");
        document
          .querySelectorAll("#submit-btn")
          .forEach((item) => item.classList.add("disabled"));
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
      }
      if (inputNumbersValue.length >= 11) {
        this.classList.remove("is-invalid");
        this.classList.add("is-valid");
        document
          .querySelectorAll("#submit-btn")
          .forEach((item) => item.classList.remove("disabled"));
      }
    } else {
      formattedInputValue = "+" + inputNumbersValue.substring(0, 16);
    }
    input.value = formattedInputValue;
  };
  let onPhoneKeyDown = function (e) {
    let inputValue = e.target.value.replace(/\D/g, "");
    if (e.keyCode == 8 && inputValue.length == 1) {
      e.target.value = "";
    }
  };

  document.querySelectorAll("#submit-btn").forEach(function (btn) {
    for (let phoneInput of phoneInputs) {
      phoneInput.addEventListener("keydown", onPhoneKeyDown);
      phoneInput.addEventListener("input", onPhoneInput, false);
      phoneInput.addEventListener("paste", onPhonePaste, false);
    }
  });
}

// Formated href:tel
const hrefTel = document.querySelectorAll('a[href^="tel:"]');
hrefTel.forEach((link) => {
  link.setAttribute("href", link.getAttribute("href").replace(/\s+/g, ""));
});

//stages step by step show
if (document.querySelector("#stages-trigger")) {
  function showStages() {
    const items = document.querySelectorAll(".stages__points__item");
    items.forEach((item) => (item.style.opacity = 0));

    let delay = 0;

    items.forEach((item) => {
      setTimeout(() => {
        item.style.opacity = 1;
      }, delay);
      delay += 750; // задержка между появлением элементов в миллисекундах
    });

    window.removeEventListener("scroll", onScroll);
  }

  function onScroll() {
    const trigger = document.querySelector("#stages-trigger");
    const triggerTop = trigger.getBoundingClientRect().top;
    const triggerBottom = trigger.getBoundingClientRect().bottom;

    if (triggerTop <= window.innerHeight && triggerBottom >= 0) {
      showStages();
      window.removeEventListener("scroll", onScroll);
    }
  }

  if (window.innerWidth > 1920) {
    showStages();
    window.removeEventListener("scroll", onScroll);
  } else {
    window.addEventListener("scroll", onScroll);
  }
}
// dropdown
const menuItems = document.querySelectorAll(".menu-item-has-children");
const dropdowns = document.querySelectorAll(".dropdown");

menuItems.forEach((item) => {
  let dropdown = item.querySelector(".dropdown");

  item.addEventListener("click", () => {
    if (dropdown.classList.contains("dropdown--dropped")) {
      dropdown.classList.remove("dropdown--dropped");
    } else {
      dropdowns.forEach((dropdown) => {
        dropdown.classList.remove("dropdown--dropped");
      });
      dropdown.classList.add("dropdown--dropped");
    }
    event.stopPropagation();
  });
});

document.addEventListener("click", () => {
  dropdowns.forEach((dropdown) => {
    dropdown.classList.remove("dropdown--dropped");
  });
});

// swiper techblog
if (document.querySelector(".techblog__catalog__swiper__page")) {
  var swiper = new Swiper(".techblog__catalog__swiper__page", {
    slidesPerView: 9,
    slidesPerPage: 9,
    spaceBetween: 10,
    pagination: {
      el: ".techblog__count",
      type: "fraction",
    },
    scrollbar: {
      el: ".techblog__scrollbar",
      // hide: true,
    },
    navigation: {
      nextEl: ".techblog__next",
      prevEl: ".techblog__prev",
    },
    breakpoints: {
      280: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 2,
      },
      767: {
        slidesPerView: 5,
      },
      1200: {
        slidesPerView: 9,
      },
    },
  });
}

// popup tools
function setSelectedTool() {
  const toolsTriggers = document.querySelectorAll(".tools-trigger");

  toolsTriggers.forEach((trigger) => {
    trigger.addEventListener("click", () => {
      const selectedTool = trigger.getAttribute("data-tools");
      const input = document.querySelector('input[name="selected_tool"]');
      input.value = selectedTool;
    });
  });
}
setSelectedTool();

document.querySelectorAll('[href="#popup"]').forEach((link) => {
  link.addEventListener("click", (event) => {
    const title = link.querySelector("span").innerText;
    const modalTitle = document.querySelector("#modal_form_title");
    modalTitle.value = "Форма заявки - модальное окно: " + title;
    const modalHeading = document.querySelector("#modal__heading");
    modalHeading.innerText = title;
  });
});

// showmore btn
if (document.querySelector(".clients__list")) {
  function showClientsList() {
    const clientsList = document.querySelector(".clients__list");
    const clients = clientsList.children;
    const button = document.getElementById("showmore_clients");
    const maxClientsNum = 8;

    if (clients.length <= maxClientsNum) {
      button.classList.remove("visually-hidden");
      if (clients.length < 9) {
        button.style.display = "none";
      }
    } else {
      for (let i = maxClientsNum; i < clients.length; i++) {
        clients[i].classList.add("visually-hidden");
      }
      button.classList.remove("visually-hidden");
      button.addEventListener("click", function () {
        for (let i = maxClientsNum; i < clients.length; i++) {
          clients[i].classList.toggle("visually-hidden");
        }
        button.style.display = "none";
      });
    }
  }

  showClientsList();
}

if (document.querySelector(".techblog__video__list")) {
  function showmoreVideos() {
    const clientsList = document.querySelector(".techblog__video__list");
    const clients = clientsList.children;
    const button = document.getElementById("showmore_videos");
    const maxClientsNum = 9;

    if (clients.length <= maxClientsNum) {
      button.classList.remove("visually-hidden");
      if (clients.length < 10) {
        button.style.display = "none";
      }
    } else {
      for (let i = maxClientsNum; i < clients.length; i++) {
        clients[i].classList.add("visually-hidden");
      }
      button.classList.remove("visually-hidden");
      button.addEventListener("click", function () {
        for (let i = maxClientsNum; i < clients.length; i++) {
          clients[i].classList.toggle("visually-hidden");
        }
        button.style.display = "none";
      });
    }
  }

  showmoreVideos();
}

if (document.querySelector("img.video__frame")) {
  let players = {};
  document.querySelectorAll("img.video__frame").forEach((img) => {
    img.addEventListener("click", () => {
      function onYouTubeIframeAPIReady() {
        players[img.id] = new YT.Player(img.id, {
          height: "100%",
          width: "100%",
          videoId: img.id,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      }
      function onPlayerReady(event) {
        event.target.playVideo();
      }
      function onPlayerStateChange(event) {}
      onYouTubeIframeAPIReady();
    });
  });
}

//menu
function toggleMenu() {
  const hamburger = document.getElementById("hamburger");
  const nav = document.querySelector(".header__nav");
  const html = document.querySelector("html");
  const main = document.querySelector("main");

  hamburger.addEventListener("click", function () {
    hamburger.classList.toggle("active");
    nav.classList.toggle("active");
    html.classList.toggle("fixed");
    main.classList.toggle("mask--main");
  });
}

toggleMenu();


particlesJS("particles-js", {
  "particles": {
    "number": {
      "value": 400,
      "density": {
        "enable": true,
        "value_area": 800
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "image",
      "stroke": {
        "width": 3,
        "color": "#fff"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "/wp-content/themes/tomast/img/snowflake3.png",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.7,
      "random": false,
      "anim": {
        "enable": false,
        "speed": 1,
        "opacity_min": 0.1,
        "sync": false
      }
    },
    "size": {
      "value": 5,
      "random": true,
      "anim": {
        "enable": false,
        "speed": 20,
        "size_min": 0.1,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 50,
      "color": "#ffffff",
      "opacity": 0.6,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 5,
      "direction": "bottom",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": true,
        "rotateX": 300,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode":  "bubble"
      },
      "onclick": {
        "enable": true,
        "mode": "repulse"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 150,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 200,
        "size": 40,
        "duration": 2,
        "opacity": 8,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.2
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
});